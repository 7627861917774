@import '../../../default';

.PageUserDashboard{
    box-sizing: border-box;
    padding: 20px;

    .area-box{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .box{
            margin: 2.5%;
            border-radius: 5px;
            box-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.5), -0.5px -0.5px 1px rgba(0, 0, 0, 0.6);
            box-sizing: border-box;
            padding: 10px;
            width: 20%;

            .title{
                font-weight: bold;
                margin: 5px;
            }

            .content{
                font-size: 4em;
                box-sizing: border-box;
                padding: 5px;
                text-align: center;
            }

            &.client{
                background-color: $color-green-d;
            }

            &.client-active{
                background-color: $color-yellow-ed;
            }

            &.user{
                background-color: $color-rose-d;
            }

            &.user-active{
                background-color: $color-purple-d;
            }
        }

    }

    .area-charts{
        width: 100;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 48% 48%;
        column-gap: 15px;

    }
}