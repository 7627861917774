@import '../../default';

$color-menu-primary: $color-blue-ed;
$color-menu-secondary: $color-blue-ed;
$color-menu-text: $color-white;

.page-user{

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;

    background-color: $color-white;

    .main-menu{
        background-color: $color-menu-primary;
        width: 100%;
        height: 100%;
        max-width: 250px;
        z-index: 10;

        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

        @media screen and (max-width: 400px) {
            max-width: 250px;
        }


        .area-logo{
            display: flex;
            box-sizing: border-box;
            padding: 10px;
            align-items: center;
            justify-content: center;
            height: 70px;

            position: relative;

            img {
                width: 70%;
                display: block;
                transform: translateY(10px);

                @media screen and (max-width: 768px) {
                    width: 60%;
                }

                &:nth-child(2){
                    display: none;
                }
            }

            .area-button-menu{

                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(100%, -50%);
                padding: 10px 15px;
                border-radius: 0 20px 20px 0;
                background-color: $color-menu-primary;

                &::before{
                    content: "";
                    position:absolute;
                    top:-20px;
                    left: 0px;
                    height:20px;
                    width: 20px;
                    border-bottom-left-radius: 50%;
                    box-shadow: 0 10px 0 0 $color-menu-primary;
                }
    
                &::after{
                    content: "";
                    position:absolute;
                    bottom:-20px;
                    left: 0px;
                    height:20px;
                    width: 20px;
                    border-top-left-radius: 50%;
                    box-shadow: 0 -10px 0 0 $color-menu-primary;
                
                }

                div {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: $color-menu-text;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all ease 0.5s;


                    svg{
                        
                        fill: $color-menu-primary;
                    }
                }
            }

        }

        &.hide{
            max-width: 80px;
            transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

            .area-logo{

                img{
                    &:nth-child(1){
                        display: none;
                    }

                    &:nth-child(2){
                        max-width: 80px;
                        width: 100%;
                        display: block;
                    }
                }
            }
           
            + section{
                max-width: calc(100% - 80px);

                header{
                    min-width: calc(100% - 80px);
                }
            }


            ul {
                padding: 0  0 0 10px;
                transition: all ease 0.5s;
                
                li{
                    a{
                        
                        > div:last-child{
                            overflow: unset;
                            position: fixed;
                            //transform: translateX(30px);
                            // display: none;
                            transition: all linear 0.1s;
                            opacity: 0;
                            pointer-events: none;
                        }
                    }

                    @media screen and (min-width: 768px){
                        &:hover{
                            a{
                                > div:last-child{
                                    min-width: unset;
                                    width: unset;
                                    display: unset;
                                    color: $color-menu-text;
                                    background-color: $color-menu-primary;
                                    box-shadow: 0 0 0px 3px $color-menu-text;
                                    box-sizing: border-box;
                                    padding: 5px;
                                    border-radius: 5px;
                                }
                            }   
                        }
                    }
                }
            }
        }

        .area-menu{
            height: 100%;
            max-height: calc(100% - 70px);
            overflow: hidden;
            overflow-y: auto;
        }

        ul {
            margin: 15px 0 0;
            padding: 0  0 0 30px;
            box-sizing: border-box;
            transition: all ease 0.5s;
            
        
            li{
                list-style: none;
                font-size: 0.8em;

                
                a{
                    &:hover{
                        // color: $color-white-d !important;
                        text-decoration: underline;

                        svg{
                            transform: scale(1.1);
                            transition: all ease 0.5s;
                            
                        }
                    }
                    box-sizing: border-box;
                    margin: 0px 0;
                    padding: 5px 15px;
                    position: relative;
                    border-radius: 20px 0 0 20px;
                    background-image: linear-gradient(to right, $color-white, $color-white);
                    background-size: 300px 100%;
                    background-repeat: no-repeat;
                    background-position: 800px 0;
                    cursor: pointer;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    text-decoration: none;
                    color: $color-menu-text;
                    transition: all linear 0.3s 0.3s;

                    > div{
                        z-index: 3;

                        &:nth-child(2){
                            overflow: hidden;
                            width: 200px;
                            min-width: 200px;
                            transition: all linear 0.3s 0.5s;
                            opacity: 1;
                        }
                    }

                    &:visited, &:active{
                        color: $color-menu-text;
                    }

                    svg{
                        margin: 5px;
                        min-width: 16px;
                        height: 16px;
                        width: 24px;
                        height: 24px;
                        transition: all ease 0.5s;
                    }

                    &::before{
                        content: "";
                        position:absolute;
                        top: -20px;
                        right: 0px;
                        height:20px;
                        width: 20px;
                        border-bottom-right-radius: 50%;
                        box-shadow: 0 10px 0 0 $color-white;
                        transition: all linear 0.3s;
                        transform: scaleX(0);
                        transform-origin: right center;
                        // opacity: 0;
                    }

                    &::after{
                        content: "";
                        position:absolute;
                        bottom: -20px;
                        right: 0px;
                        height:20px;
                        width: 20px;
                        border-top-right-radius: 50%;
                        box-shadow: 0 -10px 0 0 $color-white;
                        transition: all linear 0.3s;
                        // opacity: 0;
                        transform: scaleX(0);
                        transform-origin: right center;
                    
                    }

                    &:hover{
                        a{
                            color: $color-menu-primary;
                        }
                    }

                    &.current{
                        
                        position:relative;
                        width: 100%;
                        // height: 80px;
                        
                        transition: all ease 0.5s;
                        background-position: 0 0;

                        color: $color-menu-secondary !important;
                        font-weight: 800;

                        &::before{
                            top: -20px;
                            box-shadow: 0 10px 0 0 $color-white;
                        } 
                        &::after{
                            bottom: -20px;
                            box-shadow: 0 -10px 0 0 $color-white;
                        }

                        &::before, &::after{
                            opacity: 1;
                            transition: all linear 0.5s 0.3s;
                            right: 0;
                            transform: scaleX(1);
                        }

                    }
                }

            }
        }

    }

    section{
        width: 100vw;
        max-width: calc(100vw - 250px);
        min-width: calc(100% - 250px);
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

        header{
            width: 100%;
            min-width: calc(100% - 250px);
            height: 120px;
            // background-color: $color-blue-d;
             //background-image: linear-gradient(180deg, $color-green-l, $color-green -d);
            background-image: url(../../../../assets/image/bg-3.jpg);
            background-size: 100% auto;
            display: flex;
            align-items: flex-end;
            box-sizing: border-box;
            padding: 20px;
            color: $color-white;
            font-weight: 800;
            position: relative;
            overflow: hidden;
            border-radius: 0 0 0 15px;
            
            > div{
                position: relative;
                z-index: 3;
                font-size: 1.2em;
                min-width: 300px;
            }

            &:before{
                content: '';
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: $color-blue-d;
                opacity: 0.7;
            }
        }

        > div{
            height: calc(100% - 120px);
            overflow-y: auto;
            box-sizing: border-box;
            padding: 20px 10px;
        }
    }
}