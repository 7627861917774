@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

// Colors
$color-blue: #86c8e2;
$color-blue-l: #a6ddf7;
$color-blue-d: #6fadd1;
$color-blue-ed: #1f75a7;
$color-water: #86E2E2;
$color-water-l: #A6F7F5;
$color-water-d: #6FD1CF;
$color-yellow: #FCE781;
$color-yellow-l: #F9F695;
$color-yellow-d: #F2D277;
$color-yellow-ed: #F9D953;
$color-rose: #F7AAC7;
$color-rose-l: #F7C1D7;
$color-rose-d: #EF92C8;
$color-purple: #B494c6;
$color-purple-l: #C8ABDB;
$color-purple-d: #9C79B2;
$color-green: #C5EA8B;
$color-green-l: #CEF492;
$color-green-d: #9ED880;
$color-white-d: #F6F6F6;
$color-white: #FFFFFF;
$color-gray-d: #333;

// Color Candy Land
$color-candy-highlight-rose: #EF7FA5;
$color-candy-main-rose: #EA5E89;
$color-candy-shadow-rose: #CE4986;
$color-candy-highlight-yellow: #FCE583;
$color-candy-main-yellow: #F9D953;
$color-candy-shadow-yellow: #F2C34D;
$color-candy-highlight-green: #6DE2BE;
$color-candy-main-green: #48CCA6;
$color-candy-shadow-green: #34AD88;
$color-candy-highlight-blue: #6C5BB5;
$color-candy-main-blue: #5A449A;
$color-candy-shadow-blue: #483987;

html{
    margin: 0;
    padding: 0;

    width: 100vw;
    height: 100vh;

    overflow: hidden;

    body{
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: $color-gray-d;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: 768px){
            // font-size: 14px;
        }

        @media screen and (max-width: 500px){
            // font-size: 16px;
        }

        #root{
            width: 100vw;
            height: 100vh;
        }
    }
} 

.p-component{
    font-size: 12px;
}