@import '../../../../default';


.page-user-users-new{

    .data-user{
        border-radius: 5px;
        // box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
        box-sizing: border-box;
        padding: 10px;
    }

    form{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        row-gap: 30px;
        box-sizing: border-box;
        padding-left: 10px;
        margin-top: 40px;
        
        @media screen and (max-width: 768px){
            grid-template-columns: 100%;

            > div{
                grid-column: 1;
                
                label{
                    width: 100%;
                }
                input, select{
                    width: 100% !important;
                }
            }
        }

        

        input, select, .p-dropdown, .p-password{
            width: 100%;
        }

        .rules{

            grid-column: 1 / span 2;

            @media screen and (max-width: 768px){
                grid-column: 1;
            }

            .area-admin{
                display: grid;
                grid-template-columns: 250px auto;
                justify-content: flex-start;
                align-items: center;
                box-sizing: border-box;
                padding: 5px 5px 20px;

                

                input{
                    width: 100%;
                    height: 100%;
                }
            }

            .table{
                transition: all ease 0.5s;
                height: 100%;
                overflow: hidden;

                &.block{
                    pointer-events: none;
                    opacity: 0.5;
                    transition: all ease 0.5s;
                }
            }


            .header{
                display: grid;
                grid-template-columns: 150px auto repeat(3, 90px);
                font-weight: 800;
                background-color: $color-white !important;
                border-bottom: 2px solid $color-gray-d;
                font-weight: 800 !important;
                font-size: 1em !important;
                box-sizing: border-box;
                padding: 5px;

                @media screen and (max-width: 768px){
                    grid-template-columns: 120px auto repeat(3, 70px);
                }

                @media screen and (max-width: 768px){
                    grid-template-columns: 120px auto;
                }

                div{
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5){
                        text-align: center;

                        @media screen and (max-width: 768px){
                            display: none;
                        }
                    }
                }
        
            }

            .list-rules{

                ul{
                    margin: 0;
                    padding: 0;

                    li{
                        list-style: none;
                        margin: 5px 0;
                        padding: 10px 5px;
                        display: grid;
                        grid-template-columns: 150px auto 270px;
                        align-items: center;

                      

                        @media screen and (max-width: 768px){
                            grid-template-columns: 120px auto;
                        }
                        

                        .options-rules{
                            display: grid;
                            grid-template-columns: repeat(3, 90px);

           

                            @media screen and (max-width: 768px){
                                grid-column: 1 / span 2;
                                width: 100%;
                                display: flex;
                                margin: 5px auto;
                                
                            }
                            
                            > span{
                                display: inline-flex;
                                justify-content: center;
                                width: 33%;
                                height: 32px;
                                margin: 0 auto;
                                position: relative;

                                @media screen and (max-width: 768px){
                                    display: inline-flex;
                                    width: 33%;
                                    justify-content: flex-start;
                                    margin: 5px 0 10px;
                                    align-items: center;
                                    box-sizing: border-box;
                                    background-color: $color-blue-d;
                                    color: $color-white;
                                    overflow: hidden;

                                    + span{
                                        border-left: 1px solid $color-blue-l;
                                    }

                                    &:nth-child(1){
                                        border-radius: 5px 0 0 5px;
                                    }

                                   &:last-child{
                                        border-radius: 0 5px 5px 0;
                                    }

                                }

                                label{
                                    display: none;
                                    width: 100%;
                                    height: 100%;

                                    
                                    @media screen and (max-width: 768px){
                                        display: flex;
                                        text-align: center;
                                        box-sizing: border-box;
                                        padding: 5px;
                                        font-size: 0.9em;
                                        transition: all ease 0.5s 0.3s; 
                                        align-items: center;   
                                        justify-content: center;

                                    }
                                }
                            }

                            input{
                                display: block;
                                cursor: pointer;
                                width: 100%;
                                height: 100%;

                                @media screen and (max-width: 768px){
                                    opacity: 0;
                                    position: absolute;
                                }

                                &:checked{

                                    + label{
                                         background-color: $color-purple-d;
                                         transition: all ease 0.5s;
                                         font-weight: 800;
                                    }
                                }
                            }
                        }

                        &:nth-child(even){
                            background-color: $color-white-d;
                        }
            
                    }
                }

            }

        }

        .area-buttons{
            grid-column: 1 / span 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button + button{
                margin-left: 10px;
            }
        }
    }

}