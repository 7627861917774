@import '../../../../default';


.page-user-users-view{

    .bar-add{
        background-color: transparent;
        border: 0;
    }

    .card-user-data{
        display: grid;
        grid-template-columns: 90px repeat(2, auto);
        column-gap: 15px;
        row-gap: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 100;
        width: 100%;
        margin: 0 auto;
        border-radius: 5px;
        // box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
        box-sizing: border-box;
        padding: 5px;
        
        > div{
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: 768px) {
            grid-template-columns: 90px auto;
        }

        .avatar{
            display: flex;
            align-items: center;
            justify-content: center;
            grid-row: 1 / span 2;
            box-sizing: border-box;
            padding: 5px;
            align-items: center;
            background-color: $color-white-d;
            width: 80px;
            height: 80px;

            border-radius: 50%;
            overflow: hidden;

            i{
                font-size: 2em;
            }

            svg{
                width: 48px;
                height: 48px;

                fill: $color-blue-d;

            }

        }

        .userid{
            width: 80px;
            grid-column: 1;
            grid-row: 3;
            font-weight: 400;
            text-align: center;
            justify-content: center;

            &::before{
                content :'ID:';
                display: inline-flex;
                align-items: center;
                font-weight: 800;
                margin-right: 5px;
                min-width: 10px;
            }
        }

        .name{
            grid-column: 2;
            grid-row: 1;
            font-weight: 400;

            &::before{
                content :'Nome:';
                display: inline-flex;
                align-items: center;
                font-weight: 800;
                margin-right: 5px;
                min-width: 85px;
            }
        }

        .email{
            grid-column: 3;
            grid-row: 1;

            &::before{
                content :'E-mail:';
                display: inline-flex;
                align-items: center;
                font-weight: 800;
                margin-right: 5px;
                min-width: 85px;
            }
        }

        .phone{
            grid-column: 2;
            grid-row: 2;
            &::before{

                content :'Telefone:';
                display: inline-flex;
                align-items: center;
                font-weight: 800;
                margin-right: 5px;
                min-width: 85px;
            }
        }

        .smartphone{
            grid-column: 3;
            grid-row: 2;
            &::before{

                content :'Celular:';
                display: inline-flex;
                align-items: center;
                font-weight: 800;
                margin-right: 5px;
                min-width: 85px;
            }
        }

        .career{
            grid-column: 2;
            grid-row: 3;
            font-size: 0.8em;
            text-align: center;

            &::before{
    
                content :'Profissional:';
                display: inline-flex;
                align-items: center;
                font-weight: 800;
                margin-right: 5px;
                min-width: 85px;
            }
        }

        .status{
            grid-column: 3;
            grid-row: 3;
            font-size: 0.8em;
            text-align: center;

            &::before{
    
                content :'Status:';
                display: inline-flex;
                align-items: center;
                font-weight: 800;
                margin-right: 5px;
                min-width: 85px;
            }
        }

    }
    
    .rules{
        width: 100%;
        margin: 15px auto;
        border-radius: 5px;
        // box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
        box-sizing: border-box;
        padding: 10px;

        .list-rules{
            

            strong{
                margin: 5px 0;
                display: inline-block;
            }

            ul{
                // padding: 0;
                // margin: 0;

                li{
                    list-style: circle;
                    font-size: 0.8em;
                    box-sizing: border-box;
                    padding-left: 10px;

                    span {
                        margin: 0 5px;
                    }
                }
            }

        }
    }

}