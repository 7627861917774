@import '../../../../default';


.page-user-client-view{

    .tab-content{

        box-sizing: border-box;
        padding: 20px 0;
    } 

    .card-user-data{
        display: grid;
        grid-template-columns: 100px auto;
        box-sizing: border-box;
        padding: 10px 5px;
        margin: 5px auto 20px;
    }

    .p-toolbar{
        border: 0;
        background-color: transparent;

    }

    .identifier-client{
        font-size: 1.5rem;
    }

    .user-data{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div{
            margin: 5px;
            
            > strong{
                margin-right: 10px;
            }
        }

        .name_fantasy, 
        .ins_mun,
        .ins_est{
            display: flex;
            width: 100%;
            min-width: 100%;
            
        }

        .observacoes{
            display: flex;
            width: 100%;
            min-width: 100%;
            min-height: 50px;

            > div{
                width: 100%;
                min-width: none;
            }
        }
    }

}