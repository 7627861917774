@import '../../../../default';


.page-user-client-new{

    .data-user{
        border-radius: 5px;
        // box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
        box-sizing: border-box;
        padding: 10px;
    }

    form{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        row-gap: 30px;
        box-sizing: border-box;
        padding-left: 10px;
        margin-top: 20px;
        
        @media screen and (max-width: 768px){
            grid-template-columns: 100%;

            > div{
                grid-column: 1;
                
                label{
                    width: 100%;
                }
                input, select{
                    width: 100% !important;
                }
            }
        }

        label{
            width: 100%;
            display: block;
            font-size: 0.8em;
            font-weight: 200;
        }

        input, select, .p-dropdown, .p-password{
            width: 100%;
        }

        .area-client, .person-type-f, .area-address, .area-contact, .client-obs{
            grid-column: 1 / span 2;
        }

        .client-obs{
            textarea{
                width: 100%;
                min-height: 100px;
                border-radius: 5px;
                border: 1px solid $color-gray-d;
            }
        }

        .area-phone{
            display: grid;
            grid-template-columns: 49% 49%;
            column-gap: 15px;

            label{
                grid-row: 1;
            }
        }

        .area-buttons{
            grid-column: 1 / span 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button + button{
                margin-left: 10px;
            }
        }
    }

}