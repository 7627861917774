@import '../../../../default';


.page-user-budget-new{

    .data-user{
        border-radius: 5px;
        // box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
        box-sizing: border-box;
        padding: 10px;
    }

    form{
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;

        .p-d-flex{
            margin: 15px auto;
        }

        > .p-d-flex:first-child{
            margin-top: 30px;
        }

        .p-formgroup-inline{
            width: 100%;
            justify-content: space-between;
        }

        .p-field{
            justify-self: stretch;
            margin-right: 0;
        }

        .p-picklist-item{
            .p-d-flex{
                margin: 0px auto;

                .p-field{
                    margin: 5px auto;
                }

                [class*="p-col"]{
                    display: flex;
                    align-items: center;
                }

                .p-button{
                    width: 32px;
                    min-width: 32px;
                    max-width: 32px;
                    height: 32px;
                    min-height: 32px;
                    max-width: 32px;

                }
            }
        }
        
        @media screen and (max-width: 768px){
            grid-template-columns: 100%;

            > div{
                grid-column: 1;
                
                label{
                    width: 100%;
                }
                input, select{
                    width: 100% !important;
                }
            }
        }

        label{
            width: 100%;
            display: block;
            font-size: 0.8em;
            font-weight: 200;
        }

        input, select, .p-dropdown, .p-password, .p-inputnumber, .p-calendar{
            width: 100%;
        }

        .p-autocomplete, .client, .address, .contact{
            width: calc(100% - 180px);
        }

        .p-autocomplete + .p-button{
            width: 170px !important;
        }

        .area-client{
            margin-bottom: 30px;
        }
        .area-client, .person-type-f, .area-address, .area-contact, .client-obs{
            grid-column: 1 / span 2;
        }

        .client-obs{
            textarea{
                width: 100%;
                min-height: 100px;
                border-radius: 5px;
                border: 1px solid $color-gray-d;
            }
        }

        .area-phone{
            display: grid;
            grid-template-columns: 49% 49%;
            column-gap: 15px;

            label{
                grid-row: 1;
            }
        }

        .area-buttons{
            grid-column: 1 / span 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button + button{
                margin-left: 10px;
            }
        }
    }

}