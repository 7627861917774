@import '../../../../default';


.page-user-client-edit{

    .area-new-address{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        padding: 5px 0 20px;
    }

    .header{
        display: grid;
        grid-template-columns: 100px auto 100px;
        font-size: 1em;
        font-weight: 800;
        background-color: $color-blue-l;
        color: $color-blue-ed;

        div{
            box-sizing: border-box;
            padding: 5px;
        }

        div + div{
            border-left: 1px solid $color-white;
        }
    }

    .bar-add{
        background-color: transparent;
        border: 0;
    }

    .p-tabmenu-nav{
        margin: 0 0 25px;
    }

    .address-item{
        display: grid;
        grid-template-columns: 100px auto 100px;
        font-size: 0.8em;
        box-sizing: border-box;
        padding: 5px;

        &:nth-child(even){
            background-color: $color-white-d;
        }

        .area-buttons{
            display: flex;
            justify-content: space-between;
        }
    }


    form{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        row-gap: 30px;
        box-sizing: border-box;
        padding-left: 10px;
        margin-top: 40px;
        
        @media screen and (max-width: 768px){
            grid-template-columns: 100%;

            > div{
                grid-column: 1;
                
                label{
                    width: 100%;
                }
                input, select{
                    width: 100% !important;
                }
            }
        }

        label{
            width: 100%;
            display: block;
            font-size: 0.8em;
            font-weight: 200;
        }

        input, select, .p-dropdown, .p-password{
            width: 100%;
        }

        .area-client, .person-type-f, .area-address, .area-contact, .client-obs{
            grid-column: 1 / span 2;
        }

        .client-obs{
            textarea{
                width: 100%;
                min-height: 100px;
                border-radius: 5px;
                border: 1px solid $color-gray-d;
            }
        }

        .area-phone{
            display: grid;
            grid-template-columns: 49% 49%;
            column-gap: 15px;

            label{
                grid-row: 1;
            }
        }

        .area-buttons{
            grid-column: 1 / span 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button + button{
                margin-left: 10px;
            }
        }
    }

}