@import '../../../default';

.page-user-budget{

    a{
        text-decoration: none;
    }

    .bar-add{
        background-color: transparent;
        border: 0;
    }

}
