@import '../../default';

.page-login{

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    background-image: url(../../../image/bg-9.jpg);
    background-size: cover;

    &:before{
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $color-blue-d;
        opacity: 0.7;
    }

    


    .box-login{
        position: relative;
        z-index: 10;
        width: 100%;
        max-width: 500px;
        height: 100%;
        max-height: 300px;
        box-sizing: border-box;
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        column-gap: 10px;
        align-items: center;

        @media screen  and (max-width: 768px){
            column-gap: unset;
            row-gap: 10px;
            grid-template-columns: 100%;
            grid-template-rows: repeat(2, 50%);
        }

        .area-logo{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 100%;
            margin-bottom: 10px;

            img{
                display: block;
                width: 80%;
                
                @media screen  and (max-width: 768px){
                    width: 50%;
                }
            }


            &::before{
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                height: 100%;
                width: 1px;
                background-image: linear-gradient(45deg, transparent 20%, $color-white 30%, $color-white 70%, transparent 80%);
                display: block;
                transform: translateY(-50%);
                border-radius: 1px;

                @media screen  and (max-width: 768px){
                    top: unset;
                    right: unset;
                    bottom: 0;
                    left: 50%;
                    width: 100%;
                    height: 1px;
                    background-image: linear-gradient(0deg, transparent 20%, $color-white 30%, $color-white 70%, transparent 80%);
                    transform: translate(-50%, 0);
                    display: none;
                }
                
            }
        }

        .area-form{

            display: flex;
            height: 100%;
            align-items: center;

            form{
                width: 100%;
                > div{
                    margin: 10px auto;
                }
            }

            input{
                width: 100%;
                border: 0;
                background-color: $color-white;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 5px;

                &:focus{
                    outline: 0;
                }
            }

            button{
                margin: 15px auto;
                border: 0;
                background-color: transparent;
                display: block;
                color: $color-white;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 5px 10px;
                cursor: pointer;
                font-family: 'Open Sans', sans-serif;

            }

            .link-resetpassword{
                font-size: 0.9em;
                color: $color-white;
                text-decoration: underline;
                box-sizing: border-box;
                padding: 5px 0;
                text-align: right;
                cursor: pointer;
                font-weight: 200;

                &:hover{
                    color: $color-yellow-l;
                }
            }
            
        }
    }
}